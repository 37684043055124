<template>
  <div class="header-mobile__menu-container">
    <div class="header-mobile__menu-left">
      <div
        v-if="!isMenuExpanded"
        v-bind="menuOpenBtnAttrs"
        class="header-mobile__hamburger-btn"
        :data-ref="$options.consts.REFS.HEADER_BURGER_MENU_BUTTON"
        @click.stop="openMenu"
      >
        <div v-bind="menuOpenBtnAttrs" class="hamburger-inner"></div>
        <div v-bind="menuOpenBtnAttrs" class="hamburger-inner"></div>
        <div v-bind="menuOpenBtnAttrs" class="hamburger-inner"></div>
      </div>

      <div v-else class="header-mobile__hamburger-btn" @click="closeMenu">
        <button class="close" />
      </div>

      <div
        class="header-mobile__one-signal one-signal-mobile-container"
        :data-ref="$options.consts.REFS.ONE_SIGNAL_MOBILE"
      >
        <!-- Note: this icon is going to replace OneSignal's default Bell SVG -->
        <a-one-signal-icon
          :width="currentOneSignalIconSize"
          :height="currentOneSignalIconSize"
          :data-ref="$options.consts.REFS.ONE_SIGNAL_ICON_MOBILE"
        />
      </div>
    </div>

    <a-header-logo />

    <div class="header-mobile__menu-right">
      <client-only>
        <a-header-actions mobile />
      </client-only>
    </div>
  </div>
</template>

<script>
import AHeaderActions from '../../Shared/AHeaderActions'
import { ROUTE } from 'enums/routes'
import { REFS } from 'enums/external-refs'
import AHeaderLogo from '@/components/AHeader/AHeaderDesktop/AHeaderLogo'
import { propValidator, PROP_TYPES } from '@/utils/validators'
import AOneSignalIcon from 'shared/AOneSignalIcon'
import { HEADER_MOBILE_MENU_OPEN_BTN_ATTRIBUTE } from 'enums/data-attributes'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'

const ONE_SIGNAL_ICON_SIZE_MOBILE = 25
const ONE_SIGNAL_ICON_SIZE_TABLET = 30

export default {
  name: 'AHeaderMobileTopSection',
  mixins: [hydrationHelpers],
  components: { AOneSignalIcon, AHeaderLogo, AHeaderActions },
  model: {
    prop: 'isMenuExpanded'
  },
  props: {
    isMenuExpanded: propValidator([PROP_TYPES.BOOLEAN])
  },
  consts: {
    REFS
  },
  data() {
    return {
      ROUTE
    }
  },
  computed: {
    currentOneSignalIconSize() {
      return (
        this.$_hydrationHelpers_getValueByBreakpoint({
          [this.$breakpoint.mobile]: ONE_SIGNAL_ICON_SIZE_MOBILE,
          [this.$breakpoint.tablet]: ONE_SIGNAL_ICON_SIZE_TABLET
        }) || ONE_SIGNAL_ICON_SIZE_MOBILE
      )
    },
    menuOpenBtnAttrs() {
      return { [HEADER_MOBILE_MENU_OPEN_BTN_ATTRIBUTE]: true }
    }
  },
  methods: {
    openMenu() {
      this.$emit('input', true)
    },
    closeMenu() {
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.header-mobile__menu-container {
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-mobile__menu-left,
.header-mobile__menu-right {
  width: 71px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-mobile__hamburger-btn {
  position: relative;
  width: 31px;
  height: 21px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header-mobile__one-signal {
  position: relative;
  margin-left: 10px;
  width: 30px;
  height: 30px;

  @include mobile {
    margin-left: 15px;
    width: 25px;
    height: 25px;
  }
}

.hamburger-inner {
  width: 100%;
  height: 3px;
  border-radius: 4px;
  background-color: white;
}

.header-mobile__menu-logo {
  width: 32px;
  height: 48px;
}

.close {
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
  opacity: 0.9;

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &::before,
  &::after {
    position: absolute;
    top: -1px;
    left: 10px;
    content: ' ';
    height: 25px;
    width: 4px;
    background-color: white;
  }

  &:hover {
    opacity: 1;
  }
}
</style>
