<template>
  <div
    v-on-clickaway="onBlur"
    :class="quicksearchWrapperDynamicClass"
    @blur.capture="onElementBlur"
  >
    <a-visibility show :on="[$breakpoint.mobile]">
      <a-lazy-hydrate when-visible>
        <a-text-input
          v-model="searchPhrase"
          :focus-on-mount="focusOnMount"
          search-icon
          :input-style="$options.consts.INPUT_STYLE.STYLE_2"
          :in-progress="inProgress"
          :hide-label="hideLabel"
          :placeholder="placeholder"
          class="global-quicksearch__input"
          :class="dynamicInputClass"
          ref="search"
          @focus="isFocused = true"
          @search="openSearchPage"
          @keyup.enter="openSearchPage"
        />
      </a-lazy-hydrate>
    </a-visibility>

    <a-visibility hide :on="[$breakpoint.mobile]">
      <a-lazy-hydrate when-visible>
        <a-text-input
          v-model="searchPhrase"
          :focus-on-mount="focusOnMount"
          search-icon
          :input-style="$options.consts.INPUT_STYLE.STYLE_1"
          :in-progress="inProgress"
          :hide-label="hideLabel"
          :placeholder="placeholder"
          class="global-quicksearch__input"
          :class="dynamicInputClass"
          ref="search"
          @focus="isFocused = true"
          @search="openSearchPage"
          @keyup.enter="openSearchPage"
        />
      </a-lazy-hydrate>
    </a-visibility>

    <component
      :is="quicksearchResultsComponent"
      v-if="isDropdownVisible"
      :search-phrase="searchPhrase"
      :in-progress.sync="inProgress"
      :single-column="singleColumn"
      :source="source"
      :scrollable="scrollable"
      v-on="$listeners"
      @search="openSearchPage"
      @hook:mounted="isFocused = true"
    />

    <i
      v-if="isCloseIconVisible"
      class="close-icon close-icon_sm"
      @click="onCloseIconClick"
    />
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway'

import mixins from '@/utils/mixins'
import { PROP_TYPES, propValidator } from '@/utils/validators'
import { FM_DIR_ROUTE, ROUTE } from 'enums/routes'
import { INPUT_STYLE } from 'shared/ATextInput'
import { CLOSE_MOBILE_HEADER_MENU_EVENT } from '@/components/AHeader/AHeaderMobile/enums'
import {
  MIN_CHAR_COUNT_FOR_SEARCH,
  QUICKSEARCH_SOURCE
} from 'shared/AGlobalQuicksearch/enums'
import { MIN_CHAR_COUNT_FOR_TRACKING_SEARCH } from '@/utils/mixins/track-search'
import { mapMutations } from 'vuex'
import * as types from '@/store/mutation-types'
import { SEARCH_TRACKING_EVENT_TYPE } from 'enums/track-search'
import { hasParentWith } from '@fmpedia/helpers'

const QUICKSEARCH_SOURCES = Object.values(QUICKSEARCH_SOURCE)

const QUICKSEARCH_RESULTS_COMPONENT_BY_SOURCE = {
  [QUICKSEARCH_SOURCE.FL]: 'FlQuicksearchResults'
}

const WRAPPER_CLASS = 'global-quicksearch__wrapper'

export default {
  name: 'AGlobalQuicksearch',
  mixins: [mixins.urlGenerators, mixins.trackSearch],
  components: {
    FlQuicksearchResults: () => import('./FlQuicksearchResults')
  },
  directives: { onClickaway },
  props: {
    singleColumn: propValidator([PROP_TYPES.BOOLEAN], false, false),
    hideLabel: propValidator([PROP_TYPES.BOOLEAN], false, false),
    source: propValidator(
      [PROP_TYPES.STRING],
      false,
      QUICKSEARCH_SOURCE.FL,
      value => QUICKSEARCH_SOURCES.includes(value)
    ),
    placeholder: propValidator(
      [PROP_TYPES.STRING],
      false,
      'What are you looking for?'
    ),
    withCloseIcon: propValidator([PROP_TYPES.BOOLEAN], false, false),
    focusOnMount: propValidator([PROP_TYPES.BOOLEAN], false, false),
    useTrackSearch: propValidator([PROP_TYPES.BOOLEAN], false, true),
    scrollable: propValidator([PROP_TYPES.BOOLEAN], false, false)
  },
  consts: { INPUT_STYLE },
  data() {
    return {
      searchPhrase: '',
      isFocused: false,
      inProgress: false
    }
  },
  computed: {
    quicksearchResultsComponent() {
      return QUICKSEARCH_RESULTS_COMPONENT_BY_SOURCE[this.source]
    },
    isDropdownVisible() {
      return (
        (this.isFocused || this.$refs?.search?.isFocused) &&
        this.searchPhrase.length >= MIN_CHAR_COUNT_FOR_SEARCH
      )
    },
    isCloseIconVisible() {
      return this.withCloseIcon && !this.inProgress && this.isDropdownVisible
    },
    dynamicInputClass() {
      return {
        'no-search-icon': this.isDropdownVisible
      }
    },
    quicksearchWrapperDynamicClass() {
      return {
        [WRAPPER_CLASS]: true,
        focused: this.isFocused
      }
    }
  },
  watch: {
    '$route.path': {
      handler() {
        this.onCloseIconClick()
      }
    },
    searchPhrase: function() {
      if (
        this.useTrackSearch &&
        this.searchPhrase?.length >= MIN_CHAR_COUNT_FOR_TRACKING_SEARCH
      ) {
        this.$_trackSearch_debouncedTrackSearch(
          this.searchPhrase,
          SEARCH_TRACKING_EVENT_TYPE.FL_GLOBAL_SEARCH
        )
      }
    }
  },
  methods: {
    ...mapMutations({
      setSkipSearchTrackOnSearchResultsPageStatus: `globalSearch/${types.SET_SKIP_SEARCH_TRACK_ON_SEARCH_RESULTS_PAGE_STATUS}`
    }),
    openSearchPage() {
      if (this.searchPhrase.length < MIN_CHAR_COUNT_FOR_SEARCH) return

      if (this.source === QUICKSEARCH_SOURCE.FL) {
        this.$router.push({
          path: ROUTE.SEARCH_RESULTS,
          query: {
            searchPhrase: this.searchPhrase
          }
        })

        this.setSkipSearchTrackOnSearchResultsPageStatus(true)

        if (this.$refs.search) {
          this.$refs.search.$refs.input.blur()
        }

        this.searchPhrase = ''
        this.onBlur()
        this.$bus.$emit(CLOSE_MOBILE_HEADER_MENU_EVENT)
      } else {
        const queryString = this.$helper.serializeQueryParams({
          searchPhrase: this.searchPhrase
        })
        const link = `${this.generateFmDirUrl(
          FM_DIR_ROUTE.SEARCH_RESULTS
        )}?${queryString}`

        window.location.replace(link)
      }
    },
    onCloseIconClick() {
      this.searchPhrase = ''
      this.onBlur()
    },
    onElementBlur(event) {
      const { relatedTarget } = event

      if (
        relatedTarget &&
        !hasParentWith(event.relatedTarget, {
          className: WRAPPER_CLASS
        })
      ) {
        this.onBlur()
      }
    },
    onBlur() {
      this.$nextTick(() => {
        this.isFocused = false
        this.$emit('blur')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.global-quicksearch__wrapper {
  position: relative;
  max-width: 800px;

  /deep/ .input__input:focus {
    outline: none;
  }

  .global-quicksearch__input.no-search-icon {
    /deep/ .input__icon-action {
      display: none;
    }
  }
}

.close-icon {
  right: 8px;
  top: 8px;
}
</style>
