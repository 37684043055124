<template>
  <div class="header__logo-container">
    <a-link :to="$options.consts.LINK_TO_HOMEPAGE" :open-in-new-tab="false">
      <img
        :src="$helper.getLinkToFlBucketFile('fl-logo-white.svg')"
        alt="forexlive.com"
        title="Forex News, Technical Analysis & Trading Tools"
        class="header__logo"
      />
    </a-link>
  </div>
</template>

<script>
import { ROUTE_NAME } from 'enums/routes'

export default {
  name: 'AHeaderLogo',
  consts: {
    LINK_TO_HOMEPAGE: { name: ROUTE_NAME.ALL_NEWS }
  }
}
</script>

<style lang="scss" scoped>
.header__logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  @include mobile {
    margin-left: 6px;
  }

  .header__logo {
    display: block;
    width: 180px;
    height: 36px;
    transition: height 0.5s, width 0.5s;
    font-size: 10px;
    line-height: 14px;

    @include tablet {
      width: 150px;
      height: 30px;
    }

    @include mobile {
      width: 90px;
      height: 18px;
    }
  }
}
</style>
