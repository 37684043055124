<template>
  <div class="header-mobile-expanded__menu-container" :style="wrapperStyles">
    <a-global-quicksearch
      placeholder="Search Forexlive"
      class="header-mobile-expanded__search-bar-container"
      with-close-icon
      v-on="$listeners"
    />

    <button @click="openNewsLetterModal" class="single-social-btn top">
      <a-icon
        :icon="getOurNewsletter.icon"
        :width="15"
        :height="15"
        fill="#fff"
        class="social-icon"
        original
      />
      <p class="social-title">
        {{ getOurNewsletter.name }}
      </p>
    </button>

    <a-header-menu-block
      :menu-items="menuItemsForSectionOne"
      @onCloseMenu="closeMenu"
    >
      <template :slot="slotNameForLastBlockItems" slot-scope="{ onItemClick }">
        <li>
          <premium-button-mobile
            class="header-mobile-expanded__premium-button"
            @click="onItemClick"
          />
        </li>
      </template>
    </a-header-menu-block>

    <a-header-menu-block
      :menu-items="menuItemsForSectionTwo"
      @onCloseMenu="closeMenu"
    />

    <a-header-menu-block
      :menu-items="menuItemsForSectionThree"
      @onCloseMenu="closeMenu"
    />

    <ul class="header-mobile-expanded__menu-block-socials">
      <li v-for="social in socialList" :key="social.name">
        <a-link :to="social.link" class="single-social-btn">
          <a-icon
            :icon="social.icon"
            :width="15"
            :height="15"
            fill="#fff"
            class="social-icon"
          />
          <p class="social-title">
            {{ social.name }}
          </p>
        </a-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import AIcon, { ICON } from 'shared/AIcon'
import AHeaderMenuBlock from './AHeaderMenuBlock'
import { MODAL } from 'modals/AModalWrapper'
import AGlobalQuicksearch from 'shared/AGlobalQuicksearch'
import { propValidator, PROP_TYPES } from '@/utils/validators'
import { FM_ROUTE, ROUTE } from 'enums/routes'
import mixins from '@/utils/mixins'
import PremiumButtonMobile from '@/components/AHeader/Shared/PremiumButtonMobile'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'

const BASE_BOTTOM_PADDING = 50

export default {
  name: 'AHeaderMobileExpanded',
  mixins: [mixins.urlGenerators, hydrationHelpers],
  components: {
    PremiumButtonMobile,
    AGlobalQuicksearch,
    AHeaderMenuBlock,
    AIcon
  },
  props: {
    menuList: propValidator([PROP_TYPES.ARRAY]),
    isMenuExpanded: propValidator([PROP_TYPES.BOOLEAN])
  },
  data() {
    return {
      ICON,
      expandedSectionIndex: null,
      getOurNewsletter: {
        name: 'Subscribe to our Daily Wrap',
        icon: ICON.NEWSLETTER_WHITE
      },
      socialList: [
        {
          name: 'Join Forexlive News Updates',
          link: this.$env.TELEGRAM_CHANNEL_FL,
          icon: ICON.TELEGRAM
        },
        {
          name: 'Join Forexlive FX & Crypto Chat',
          link: this.$env.TELEGRAM_CHANNEL_CRYPTO,
          icon: ICON.TELEGRAM
        },
        {
          name: 'Follow us on Twitter',
          link: this.$env.TWITTER_CHANNEL,
          icon: ICON.TWITTER
        },
        {
          name: 'Follow Us on Facebook',
          link: this.$env.FACEBOOK_CHANNEL,
          icon: ICON.FACEBOOK
        }
      ]
    }
  },
  computed: {
    ...mapState({
      isSafari: state => state.isSafari,
      isIPhone: state => state.isIPhone
    }),
    ...mapGetters({
      mobileBottomPadding: 'mobileBottomPadding'
    }),
    wrapperStyles() {
      return {
        paddingBottom: `${this.mobileBottomPadding + BASE_BOTTOM_PADDING}px`
      }
    },
    menuItemsForSectionOne() {
      return [
        {
          Name: 'Home',
          Link: ROUTE.ALL_NEWS
        },
        ...this.menuList
      ]
    },
    slotNameForLastBlockItems() {
      return `bottom-items-${this.menuItemsForSectionOne.length - 1}`
    },
    menuItemsForSectionTwo() {
      return [
        {
          Name: 'Brokers',
          Link: ROUTE.FL_DIR_HP
        }
      ]
    },
    menuItemsForSectionThree() {
      return [
        {
          Name: 'Events',
          Link: this.generateFmUrl(FM_ROUTE.EVENTS)
        },
        {
          Name: 'About Forexlive',
          Link: null,
          ChildItems: [
            {
              Name: 'Contact Us',
              Link: ROUTE.CONTACT_US
            },
            {
              Name: 'Terms of Use',
              Link: ROUTE.TERMS_OF_USE
            },
            {
              Name: 'Privacy',
              Link: ROUTE.PRIVACY
            }
          ]
        }
      ]
    }
  },
  watch: {
    isMenuExpanded: {
      handler(newVal) {
        if (newVal) return this.$_hydrationHelpers_hideScroll()

        this.$_hydrationHelpers_showScroll()
      }
    }
  },
  methods: {
    closeMenu() {
      this.$emit('onCloseMenu')
    },
    openNewsLetterModal() {
      this.$helper.openModal(MODAL.NEWSLETTER_FORM)
      this.closeMenu()
    }
  }
}
</script>

<style lang="scss" scoped>
.header-mobile-expanded__menu-container {
  height: calc(100vh - #{$mobile-header-height});
  width: 100%;
  padding-bottom: 10px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  /deep/ .input__wrapper {
    border: none;
  }
}

.header-mobile-expanded__search-bar-container {
  margin: 10px auto 0;
  width: 80%;
  max-width: 800px;

  @include mobile {
    width: 100%;
    margin: 10px 0 0;
  }
}

.header-mobile-expanded__menu-block-socials {
  display: flex;
  flex-direction: column;
  padding: 12px 0 7px 0;
  border-bottom: 2px solid white;

  &:last-child {
    border-bottom: none;
  }
}

.forexlive-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.forexlive-icon {
  margin-left: 6px;
}

.forexlive-title {
  color: white;
  transition: color 0.3s;

  &:hover {
    color: $c--gray-dark;
  }
}

.header-mobile-expanded__premium-button {
  margin: 6px 0 8px;
}

.social-title {
  font-weight: lighter;
  font-size: 16px;
  font-family: $font-sans;
  transition: color 0.3s;
  color: $c--white;
}

.single-social-btn {
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 10px 0;

  &:hover {
    color: $c--gray-dark;
  }

  &.top {
    margin-top: 10px;
    padding-bottom: 0;
  }
}

.social-icon {
  margin-right: 10px;
}
</style>
