<template>
  <a-link
    :to="generatePremiumUrl()"
    class="premium-button__wrapper"
    @click="$emit('click')"
  >
    <a-icon
      :icon="$options.consts.ICON.PREMIUM"
      original
      :width="13"
      :height="14"
      class="premium-button__icon"
    />
    <p class="premium-button__text">Premium</p>
  </a-link>
</template>

<script>
import mixins from '@/utils/mixins'
import AIcon, { ICON } from 'shared/AIcon'

export default {
  name: 'PremiumButtonMobile',
  mixins: [mixins.urlGenerators],
  components: { AIcon },
  consts: { ICON }
}
</script>

<style lang="scss" scoped>
.premium-button__wrapper {
  display: flex;
  align-items: center;

  .premium-button__icon {
    width: 13px;
    height: 14px;
  }

  .premium-button__text {
    margin-left: 7px;
    font-weight: lighter;
    font-size: 16px;
    font-family: $font-sans;
    transition: color 0.3s;
    color: $c--orange;
  }
}
</style>
